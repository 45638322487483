export const INSTRUCTIONS = {
  steps: [
    {
      title: "",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ",
    },
  ],
};

export function SideInfo() {
  return (
    <div class=" text-16px w-full ">
      {/* <p class="font-bold">{title}</p> */}
      {INSTRUCTIONS.steps.map((step) => (
        <div>
          <p class="mt-2">{step.title} </p>
          <p class="leading-22.4px"> {step.description}</p>
        </div>
      ))}
    </div>
  );
}
